import * as SentrySvelte from '@sentry/svelte'
import { PUBLIC_LOGROCKET_APP_ID } from '$env/static/public'
import { basicSentryConfig } from '$lib/utilities/errorTrackingTools.js'
import LogRocket from 'logrocket'
import { dev } from '$app/environment'
import SiruAxios from '$lib/SiruAxios'
import { error as errorStore, walletPaymentAuthentications } from '$lib/stores'
import { LOCALSTORAGE_WALLET_PAYMENT_AUTHENTICATIONS } from '$lib/stores/walletPaymentAuthentications'
import { getLanguageFromBCP47, supportedLocales } from '$lib/i18n'
import { locale } from 'svelte-i18n'
import { logIfInIframe } from '$lib/utilities/iFrameUtilities.js'

// Add catch-all error-handler to axios
SiruAxios.interceptors.response.use(
    response => response,
    error => {
        if (error.config.useGlobalErrorHandler === false) {
            return Promise.reject(error)
        }

        if (error.response?.status === 400) {
            errorStore.set(error.response.status)
        }

        return Promise.reject(error)
    },
)

if (!dev) {
    // Initialize our error trackers
    window.lrPromise.then(() => LogRocket.init(PUBLIC_LOGROCKET_APP_ID))

    SentrySvelte.init({
        ...basicSentryConfig,
        integrations: [SentrySvelte.browserTracingIntegration()],
    })

    SentrySvelte.setTag('svelteKit', 'browser')
}

walletPaymentAuthentications.subscribe(state => {
    localStorage.setItem(LOCALSTORAGE_WALLET_PAYMENT_AUTHENTICATIONS, JSON.stringify(state))
})

// Set initial locale from browser language
const browserLanguage = getLanguageFromBCP47(window.navigator.language)
if (supportedLocales.includes(browserLanguage)) {
    locale.set(browserLanguage)
}

// This will catch errors in load functions from +page.ts files
export const handleError = ({ error, event }) => {
    console.error(error)
    SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } })

    return {
        message: error.message,
    }
}

logIfInIframe()
